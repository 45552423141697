import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
// import firebase from "firebase/app";
// import Vue from "vue";
// import firebase from "firebase/app";
// import { nanoid } from "nanoid";
const recurringOrders = {
  namespaced: true,
  state: {
    init: false,
    recurringOrders: [],
    eId: null,
    eName: "",
    eDays: [],
    eOrderLines: [],
    eActive: true,
    eNote: "",
    eNextAvailableDates: [],
    eCompany: null,
    ePriceList: null,
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
    setEId: (state, data) => {
      state.eId = data;
    },
    setEName: (state, data) => {
      state.eName = data;
    },
    setEDays: (state, data) => {
      state.eDays = data;
    },
    setEOrderLines: (state, data) => {
      state.eOrderLines = data;
    },
    setEActive: (state, data) => {
      state.eActive = data;
    },
    setENote: (state, data) => {
      state.eNote = data;
    },
    setENextAvailableDates: (state, data) => {
      state.eNextAvailableDates = data;
    },
    setECompany: (state, data) => {
      state.eCompany = data;
    },
    setEPriceList: (state, data) => {
      state.ePriceList = data;
    },
  },
  getters: {
    currentOrder: (state) => {
      if (state.eId === null || state.eId === "create") {
        return null;
      }

      return;
    },
  },
  actions: {
    bindRecurringOrders: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "recurringOrders",
        db.collection("recurringOrders")
      );
    }),
    unbindRecurringOrders: firestoreAction((context) => {
      return context.unbindFirestoreRef("recurringOrders");
    }),
    // async saveRecurringOrder({ state }) {},
    clearEditing({ commit }) {
      commit("setEId", null);
      commit("setEName", "");
      commit("setEDays", []);
      commit("setEOrderLines", []);
      commit("setEActive", true);
      commit("setENote", "");
      commit("setENextAvailableDates", []);
      commit("setECompany", null);
      commit("setEPriceList", null);
    },
    // async deleteRecurringOrder({ state }) {},
    async setupEditing({ commit, dispatch }, payload) {
      console.log(payload);
      commit("setEName", payload.name);
      commit("setEDays", payload.days);
      commit("setEOrderLines", payload.orderLines);
      commit("setEActive", payload.active);
      commit("setENote", payload.orderNote);
      commit("setENextAvailableDates", payload.nextAvailableDates);
      commit("setEId", payload.id);
      commit("setECompany", payload.companyVismaId);

      // Run setupPriceList
      await dispatch("setupPriceList", payload.companyVismaId);
    },
    async setupPriceList({ commit }, payload) {
      console.log(payload);
      const priceList = await db
        .collection("priceListsNew")
        .doc(payload.toString())
        .get();

      commit("setEPriceList", priceList.data().products);
    },
    async deleteOrder({ state, dispatch }) {
      await db.collection("recurringOrders").doc(state.eId).delete();
      dispatch("clearEditing");
    },
  },
};
export default recurringOrders;
