var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-universal-fields"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Layout")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"horizontal","value":"horizontal"},domProps:{"checked":_vm.selectedBlock.layout === 'horizontal'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'layout',
              type: 'text',
              value: 'horizontal',
            })}}}),_c('label',{attrs:{"for":"horizontal"}},[_vm._v("Horizontal")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"vertical","value":"vertical"},domProps:{"checked":_vm.selectedBlock.layout === 'vertical'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'layout',
              type: 'text',
              value: 'vertical',
            })}}}),_c('label',{attrs:{"for":"vertical"}},[_vm._v("Vertical")])])]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Produkter")]),_c('draggable',{staticClass:"shortlists-products-inner",on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.selectedBlock.products),callback:function ($$v) {_vm.$set(_vm.selectedBlock, "products", $$v)},expression:"selectedBlock.products"}},_vm._l((_vm.selectedBlock.products),function(product){return _c('div',{key:'selected-' + product},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.getProductByFirebaseId(product).name))]),_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.productRemove(product)}}},[_vm._v("Remove")])])}),0)],1),_c('div',{staticClass:"shortlists-products-search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"search","placeholder":"Search product..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('div',{staticClass:"shortlist-search-container"},_vm._l((_vm.getProductsBySearch(_vm.search)),function(product){return _c('div',{key:product.id},[_c('span',{staticClass:"id"},[_vm._v(_vm._s(product.vismaId))]),_c('span',{staticClass:"stock"},[_vm._v(_vm._s(product.stock)+_vm._s(product.unit))]),_c('span',{staticClass:"pic"},[(product.image != '')?_c('span',{staticClass:"material-icons"},[_vm._v(" image ")]):_vm._e()]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(product.name))]),(_vm.selectedBlock.products.includes(product.firebaseId))?_c('span',{staticClass:"action remove",on:{"click":function($event){return _vm.productRemove(product.firebaseId)}}},[_vm._v("Remove")]):_c('span',{staticClass:"action add",on:{"click":function($event){return _vm.productAdd(product.firebaseId)}}},[_vm._v("Add")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }