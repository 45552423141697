<template>
  <div class="web-pages-editor">
    <div class="app-slider-editor-wrapper">
      <div class="web-pages-current-blocks-container">
        <div
          v-if="editingSliderItems.length > 0"
          class="web-pages-current-blocks"
        >
          <draggable
            v-model="editingSliderItems"
            @start="onDragStart"
            @end="onDragEnd"
          >
            <div
              v-for="(block, index) in editingSliderItems"
              :key="'block-' + index"
              class="web-pages-current-single-block"
              :class="{ active: index === selectedSliderIndex }"
              @click="selectBlock(index)"
            >
              <p>{{ block.type }} - {{ block.title.en }}</p>
              <span
                class="material-icons delete-icon"
                @click.stop="deleteBlock(index)"
                >delete</span
              >
            </div>
          </draggable>
        </div>
        <div v-else class="web-pages-no-blocks">
          <p>No slides added yet</p>
        </div>
        <button @click="addBlock">Add block</button>
      </div>

      <div
        v-if="selectedSliderItem !== false"
        class="web-pages-selected-block-wrapper"
      >
        <div class="web-pages-universal-fields">
          <div class="form-input">
            <label>Type</label>
            <div class="form-input-radio">
              <input
                type="radio"
                id="news"
                value="news"
                :checked="selectedSliderItem.type === 'news'"
                @change="
                  $store.commit('webPages/updateSliderItem', {
                    key: 'type',
                    value: 'news',
                  })
                "
              />

              <label for="news">News</label>
            </div>

            <div class="form-input-radio">
              <input
                type="radio"
                id="page"
                value="page"
                :checked="selectedSliderItem.type === 'page'"
                @change="
                  $store.commit('webPages/updateSliderItem', {
                    key: 'type',
                    value: 'page',
                  })
                "
              />

              <label for="page">Page</label>
            </div>
          </div>

          <div class="form-input" v-if="selectedSliderItem.type === 'page'">
            <label>Select page</label>
            <div
              class="form-input-radio"
              v-for="page in webPages"
              :key="page.id"
            >
              <input
                type="radio"
                :id="page.id"
                :value="page.id"
                :checked="selectedSliderItem.identifier === page.id"
                @change="
                  $store.commit('webPages/updateSliderItem', {
                    key: 'identifier',
                    value: page.id,
                  })
                "
              />

              <label :for="page.id">{{ page.id }}</label>
            </div>
          </div>

          <div class="form-input" v-if="selectedSliderItem.type === 'news'">
            <label>Select news</label>
            <div
              class="form-input-radio"
              v-for="post in availableNewsPosts"
              :key="post.id"
            >
              <input
                type="radio"
                :id="post.id"
                :value="post.id"
                :checked="selectedSliderItem.identifier === post.id"
                @change="
                  $store.commit('webPages/updateSliderItem', {
                    key: 'identifier',
                    value: post.id,
                  })
                "
              />

              <label :for="post.id">{{ post.title }}</label>
            </div>
          </div>

          <div class="form-input">
            <label>Image url</label>
            <input
              type="text"
              placeholder="Title"
              :value="selectedSliderItem.image"
              @input="
                $store.commit('webPages/updateSliderItem', {
                  key: 'image',
                  value: $event.target.value,
                })
              "
            />
          </div>
        </div>

        <div class="web-pages-lang-fields">
          <div
            v-for="(lang, index) in enabledLanguages"
            :key="'lang-' + index"
            class="web-pages-lang-group"
          >
            <img :src="require('../../assets/flags/' + lang.code + '.png')" />
            <div class="form-input">
              <label>Title</label>
              <input
                type="text"
                placeholder="Title"
                :value="selectedSliderItem.title[lang.code]"
                @input="
                  $store.commit('webPages/updateSliderItem', {
                    key: 'title.' + lang.code,
                    value: $event.target.value,
                  })
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <WebPagesEditorActions />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "WebPagesMenuEditor",
  components: { draggable },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedSliderIndex: (state) => state.webPages.selectedSliderIndex,
      webPages: (state) => state.webPages.webPages,
      availableNewsPosts: (state) => state.webPages.availableNewsPosts,
    }),
    ...mapGetters("languages", ["enabledLanguages"]),
    ...mapGetters("webPages", ["selectedSliderItem"]),
    editingSliderItems: {
      get() {
        return this.$store.state.webPages.editingSliderItems;
      },
      set(value) {
        this.$store.commit("webPages/setEditingSliderItems", value);
      },
    },
  },
  mounted() {},
  methods: {
    onDragStart: function (evt) {
      this.$store.commit("webPages/setSelectedSliderIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      this.$store.commit("webPages/setSelectedSliderIndex", evt.newIndex);
    },
    selectBlock: function (index) {
      if (this.selectedSliderIndex === index) {
        index = null;
      }

      this.$store.commit("webPages/setSelectedSliderIndex", index);
    },
    deleteBlock: function (index) {
      console.log("run");
      console.log(index);
      const confirm = window.confirm(
        "Are you sure you want to delete this slide?"
      );

      if (confirm) {
        this.$store.commit("webPages/setSelectedBlockIndex", null);
        this.$store.commit("webPages/deleteSliderItem", index);
      }
    },
    addBlock: function () {
      const titleContent = {};
      this.enabledLanguages.forEach((lang) => {
        titleContent[lang.code] = "";
      });

      const blockData = {
        type: "page",
        identifier: "null",
        title: titleContent,
        image: "",
      };

      this.$store.commit("webPages/addSliderItem", blockData);
      this.$store.commit(
        "webPages/setSelectedSliderIndex",
        this.editingSliderItems.length - 1
      );
    },
  },
  created() {
    // console.log(this.webPages);
  },
};
</script>
