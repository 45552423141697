<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div class="web-pages-universal-fields">
      <div class="form-input">
        <label>Text size</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="large"
            value="large"
            :checked="selectedBlock.size === 'large'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'size',
                type: 'text',
                value: 'large',
              })
            "
          />

          <label for="large">Large</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="regular"
            value="regular"
            :checked="selectedBlock.size === 'regular'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'size',
                type: 'text',
                value: 'regular',
              })
            "
          />
          <label for="regular">Regular</label>
        </div>
      </div>

      <div class="form-input">
        <label>Text align</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="left"
            value="left"
            :checked="selectedBlock.align === 'left'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'align',
                type: 'text',
                value: 'left',
              })
            "
          />

          <label for="left">Left</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="center"
            value="center"
            :checked="selectedBlock.align === 'center'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'align',
                type: 'text',
                value: 'center',
              })
            "
          />

          <label for="center">Center</label>
        </div>
      </div>
    </div>
    <div class="web-pages-lang-fields">
      <div
        v-for="(lang, index) in enabledLanguages"
        :key="'lang-' + index"
        class="web-pages-lang-group"
      >
        <img :src="require('../../assets/flags/' + lang.code + '.png')" />
        <div class="form-input">
          <label>Text</label>
          <textarea
            placeholder="Text"
            rows="10"
            :value="selectedBlock.text[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'text.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesTextForm",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
};
</script>
