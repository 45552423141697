<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div class="web-pages-universal-fields">
      <div class="form-input">
        <label>Text size</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="h1"
            value="h1"
            :checked="selectedBlock.size === 'h1'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'size',
                type: 'text',
                value: 'h1',
              })
            "
          />

          <label for="h1">H1</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="h2"
            value="h2"
            :checked="selectedBlock.size === 'h2'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'size',
                type: 'text',
                value: 'h2',
              })
            "
          />
          <label for="h2">H2</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="h3"
            value="h3"
            :checked="selectedBlock.size === 'h3'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'size',
                type: 'text',
                value: 'h3',
              })
            "
          />
          <label for="h3">H3</label>
        </div>
      </div>

      <div class="form-input">
        <label>Text align</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="left"
            value="left"
            :checked="selectedBlock.align === 'left'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'align',
                type: 'text',
                value: 'left',
              })
            "
          />

          <label for="left">Left</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="center"
            value="center"
            :checked="selectedBlock.align === 'center'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'align',
                type: 'text',
                value: 'center',
              })
            "
          />

          <label for="center">Center</label>
        </div>
      </div>
    </div>
    <div class="web-pages-lang-fields">
      <div
        v-for="(lang, index) in enabledLanguages"
        :key="'lang-' + index"
        class="web-pages-lang-group"
      >
        <img :src="require('../../assets/flags/' + lang.code + '.png')" />
        <div class="form-input">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            :value="selectedBlock.text[lang.code]"
            @input="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'text.' + lang.code,
                type: 'text',
                value: $event.target.value,
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesTitleForm",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
};
</script>
