/* eslint-disable */
import { db } from "../main.js";
import Vue from "vue";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";

const analytics = {
  namespaced: true,
  state: {
    init: false,
    analyticsDocs: [],
    selectedWeek: [],
    accountOwner: "all",
    usersWithNoOrders: false,
    newsAndOffersAnalytics: [],
    productSearchInfo: false,
    productFilterInfo: false,
    selectedTypes: ["app", "web"],
  },
  mutations: {
    setInit: (state, data) => {
      state.init = data;
    },
    setSelectedWeek: (state, data) => {
      state.selectedWeek = data;
    },
    setAccountOwner: (state, data) => {
      state.accountOwner = data;
    },
    setUsersWithNoOrders: (state, data) => {
      state.usersWithNoOrders = data;
    },
    setNewsAndOffersAnalytics: (state, data) => {
      state.newsAndOffersAnalytics = data;
    },
    setProductSearchInfo: (state, data) => {
      state.productSearchInfo = data;
    },
    setProductFilterInfo: (state, data) => {
      state.productFilterInfo = data;
    },
    setAnalyticsDocs: (state, data) => {
      state.analyticsDocs = data;
    },
    updateSelectedTypes: (state, data) => {
      if (state.selectedTypes.includes(data)) {
        state.selectedTypes = state.selectedTypes.filter(
          (type) => type !== data
        );
      } else {
        state.selectedTypes.push(data);
      }
    },
  },
  getters: {
    activeAnalyticsDocs: (state) => {
      return state.analyticsDocs.filter((doc) => doc.active);
    },
    activeAnalyticsSignedInDocs: (state, getters) => {
      return getters.activeAnalyticsDocs.filter(
        (doc) => doc.hasSignedIn === true
      );
    },
    activeAnalyticsSignedInPushNotificationsEnabledDocs: (state, getters) => {
      return getters.activeAnalyticsDocs.filter(
        (doc) =>
          doc.hasSignedIn === true &&
          doc.pushNotifications !== undefined &&
          doc.pushNotifications === true
      );
    },
    activeAnalyticsPushNotificationsEnabledCount: (state, getters) => {
      return getters.activeAnalyticsDocs.filter(
        (doc) =>
          doc.pushNotifications !== undefined &&
          doc.pushNotifications === false &&
          doc.hasSignedIn === true
      ).length;
    },
    activeAnalyticsPushNotificationsDisabledCount: (state, getters) => {
      return getters.activeAnalyticsDocs.filter(
        (doc) =>
          doc.pushNotifications !== undefined &&
          doc.pushNotifications === false &&
          doc.hasSignedIn === true
      ).length;
    },
    signedInUsersWithoutOrders: (state, getters) => {
      return getters.activeAnalyticsDocs.filter((user) => {
        if (
          state.accountOwner !== "all" &&
          user.accountOwner !== state.accountOwner
        ) {
          return false;
        }

        if (user.hasSignedIn === true && user.hasPlacedOrder === false) {
          return true;
        }

        return false;
      });
    },
    analyticsDocById: (state) => (id) => {
      return state.analyticsDocs.find((doc) => doc.id === id);
    },
    users: (state, getters, rootState) => {
      let users = rootState.users.userList;

      if (state.accountOwner !== "all" && state.accountOwner !== null) {
        users = users.filter((user) => {
          const analyticsDoc = state.analyticsDocs.find(
            (doc) => doc.id == user.id
          );
          if (analyticsDoc === undefined) {
            return false;
          }

          return analyticsDoc.accountOwner === state.accountOwner;
        });
      }

      return users;
    },
    loggedInUsers: (state, getters) => {
      return getters.users.filter((user) => user.firstSignIn !== undefined);
    },
    companies: (state, getters, rootState) => {
      let companies = rootState.companies.companies;
      console.log(state.accountOwner);
      if (state.accountOwner !== "all" && state.accountOwner !== null) {
        companies = companies.filter(
          (company) => company.accountOwnerVismaId === state.accountOwner
        );
      }

      return companies;
    },
    companiesWithNeverSignedInUsers: (state, getters, rootState) => {
      let users = rootState.users.userList;
      let companiesWithNeverSignedInUsers = [];

      getters.companies.forEach((company) => {
        let usersFromCompany = users.filter(
          (user) => user.companyId === company.vismaId
        );

        if (
          usersFromCompany.find((user) => user.firstSignIn !== undefined) ===
          undefined
        ) {
          companiesWithNeverSignedInUsers.push(company);
        }
      });

      return companiesWithNeverSignedInUsers;
    },
    companiesWithoutUsers: (state, getters, rootState) => {
      let users = rootState.users.userList;

      let companiesWithoutUsers = [];

      getters.companies.forEach((company) => {
        if (
          users.find((user) => user.companyId === company.vismaId) === undefined
        ) {
          companiesWithoutUsers.push(company);
        }
      });

      return companiesWithoutUsers;
    },
    productSearches: (state) => {
      const productSearches = [];
      const types = state.selectedTypes;

      state.selectedWeek.forEach((entry) => {
        entry.productSearch.forEach((search) => {
          if (
            (types.includes("app") && search.version !== "web") ||
            (types.includes("web") && search.version === "web")
          ) {
            productSearches.push({ term: search.searchTerm, uid: entry.uid });
          }
        });
      });

      // Convert to object that counts the number of times a search term is used
      const productSearchesObject = productSearches.reduce((acc, curr) => {
        if (typeof acc[curr.term] == "undefined") {
          acc[curr.term] = { count: 1, uids: [curr.uid] };
        } else {
          acc[curr.term].count += 1;
          acc[curr.term].uids.push(curr.uid);
        }
        return acc;
      }, {});

      // Sort the object by the number of times a search term is used
      const productSearchesSorted = Object.keys(productSearchesObject).sort(
        (a, b) =>
          productSearchesObject[b].count - productSearchesObject[a].count
      );

      // Convert the sorted object to an array of objects
      const productSearchesArray = productSearchesSorted.map((searchTerm) => {
        return {
          searchTerm: searchTerm,
          count: productSearchesObject[searchTerm].count,
          uids: [...new Set(productSearchesObject[searchTerm].uids)],
        };
      });

      return productSearchesArray;
    },
    appProductPages: (state, getters, rootState, rootGetters) => {
      const productPages = [];
      const types = state.selectedTypes;

      console.log(state.selectedWeek);
      // Create array of arrays with selected filters
      state.selectedWeek.forEach((entry) => {
        if (
          entry.productPage !== undefined &&
          entry.productPage.opened !== undefined
        ) {
          entry.productPage.opened.forEach((page) => {
            if (
              (types.includes("app") && page.version !== "web") ||
              (types.includes("web") && page.version === "web")
            ) {
              productPages.push({
                firebaseId: page.firebaseId,
                vismaId: page.vismaId,
              });
            }
          });
        }
      });

      let productPagesObject = {};
      if (productPages.length > 0) {
        productPagesObject = productPages.reduce((acc, curr) => {
          if (typeof acc[curr.firebaseId] == "undefined") {
            acc[curr.firebaseId] = { count: 1, vismaId: curr.vismaId };
          } else {
            acc[curr.firebaseId].count += 1;
          }
          return acc;
        });
      }

      delete productPagesObject.firebaseId;
      delete productPagesObject.vismaId;

      console.log(productPagesObject);

      // Sort by count
      const productPagesSorted = Object.keys(productPagesObject).sort(
        (a, b) => productPagesObject[b].count - productPagesObject[a].count
      );

      console.log(productPagesSorted);

      // Convert to array
      const productPagesArray = productPagesSorted.map((page) => {
        return {
          firebaseId: page,
          vismaId: productPagesObject[page].vismaId,
          count: productPagesObject[page].count,
        };
      });

      return productPagesArray;
    },
    categoryIcons: (state, getters, rootState, rootGetters) => {
      const categoryIcons = [];

      const types = state.selectedTypes;

      // Create array of arrays with selected filters
      state.selectedWeek.forEach((entry) => {
        entry.categoryIcons.forEach((category) => {
          if (
            (types.includes("app") && category.version !== "web") ||
            (types.includes("web") && category.version === "web")
          ) {
            categoryIcons.push({
              category: category.category,
              uid: entry.uid,
            });
          }
        });
      });

      // Convert to object that counts the number of times a filter is used
      const categoryIconsObject = categoryIcons.reduce((acc, curr) => {
        if (typeof acc[curr.category] == "undefined") {
          acc[curr.category] = { count: 1, uids: [curr.uid] };
        } else {
          acc[curr.category].count += 1;
          acc[curr.category].uids.push(curr.uid);
        }
        return acc;
      }, {});

      // Sort the object by the number of times a filter is used
      const categoryIconsSorted = Object.keys(categoryIconsObject).sort(
        (a, b) => categoryIconsObject[b].count - categoryIconsObject[a].count
      );

      const categoriesLabels =
        rootGetters["settings/categoryGroupsTranslated"].en;

      console.log(categoryIconsSorted);

      // Convert the sorted object to an array of objects
      const categoryIconsArray = categoryIconsSorted.map((category) => {
        console.log(category);
        const label =
          categoriesLabels[category] !== undefined
            ? categoriesLabels[category]
            : "Deleted category";

        return {
          category: label,
          count: categoryIconsObject[category].count,
          uids: [...new Set(categoryIconsObject[category].uids)],
        };
      });

      console.log(categoryIconsArray);

      return categoryIconsArray;
    },
    productFilters: (state, getters, rootState, rootGetters) => {
      const productFilters = [];

      const types = state.selectedTypes;

      // Create array of arrays with selected filters
      state.selectedWeek.forEach((entry) => {
        entry.productFilter.forEach((filter) => {
          if (
            (types.includes("app") && filter.version !== "web") ||
            (types.includes("web") && filter.version === "web")
          ) {
            productFilters.push({
              filter: filter.selected.join(","),
              uid: entry.uid,
            });
          }
        });
      });

      // Convert to object that counts the number of times a filter is used
      const productFiltersObject = productFilters.reduce((acc, curr) => {
        if (typeof acc[curr.filter] == "undefined") {
          acc[curr.filter] = { count: 1, uids: [curr.uid] };
        } else {
          acc[curr.filter].count += 1;
          acc[curr.filter].uids.push(curr.uid);
        }
        return acc;
      }, {});

      // Sort the object by the number of times a filter is used
      const productFiltersSorted = Object.keys(productFiltersObject).sort(
        (a, b) => productFiltersObject[b].count - productFiltersObject[a].count
      );

      const categoriesLabels = rootGetters["settings/productCategories"].en;
      const categoryGroupsLabels =
        rootGetters["settings/categoryGroupsTranslated"].en;

      // Convert the sorted object to an array of objects
      const productFiltersArray = productFiltersSorted.map((filter) => {
        // Convert filter string to array
        const filterArray = filter.split(",");
        // Convert filter array to array of labels
        const filterLabels = filterArray.map((filter) => {
          let label = "Deleted category";

          if (categoriesLabels[filter] !== undefined) {
            label = categoriesLabels[filter] + "(old categories)";
          } else if (categoryGroupsLabels[filter] !== undefined) {
            label = categoryGroupsLabels[filter];
          }

          return label;
        });
        // Convert filter labels to string
        const label = filterLabels.join(", ");

        return {
          filter: label,
          count: productFiltersObject[filter].count,
          uids: [...new Set(productFiltersObject[filter].uids)],
        };
      });

      return productFiltersArray;
    },
    addedToCartDoughnut: (state) => {
      let allProducts = 0;
      let recommended = 0;
      let newsAndOffers = 0;
      let shortlist = 0;
      let chat = 0;
      let cartUpsell = 0;
      let homeUpsell = 0;

      const types = state.selectedTypes;

      state.selectedWeek.forEach((entry) => {
        if (entry.cart.added !== undefined) {
          entry.cart.added.forEach((addEntry) => {
            if (
              (types.includes("app") && addEntry.version !== "web") ||
              (types.includes("web") && addEntry.version === "web")
            ) {
              switch (addEntry.source) {
                case "recommendedShortlist":
                  recommended++;
                  break;
                case "allProducts":
                  allProducts++;
                  break;
                case "shortlist":
                  shortlist++;
                  break;
                case "post":
                  newsAndOffers++;
                  break;
                case "chat":
                  chat++;
                  break;
                case "cartUpsell":
                  cartUpsell++;
                  break;
                case "homeUpsell":
                  homeUpsell++;
                  break;
                default:
                  console.log("Unknown case: " + addEntry.source);
              }
            }
          });
        }
      });

      return {
        labels: [
          "All products",
          "Recommended",
          "News & Offers",
          "Shortlist",
          "Chat",
          "Upsell",
          "Home Upsell",
        ],
        datasets: [
          {
            data: [
              allProducts,
              recommended,
              newsAndOffers,
              shortlist,
              chat,
              cartUpsell,
              homeUpsell,
            ],
          },
        ],
      };
    },
    removedFromCartDoughnut: (state) => {
      let allProducts = 0;
      let recommended = 0;
      let newsAndOffers = 0;
      let shortlist = 0;
      let chat = 0;
      let cartUpsell = 0;
      let cart = 0;
      let homeUpsell = 0;

      const types = state.selectedTypes;

      state.selectedWeek.forEach((entry) => {
        if (entry.cart.removed !== undefined) {
          entry.cart.removed.forEach((entry) => {
            if (
              (types.includes("app") && entry.version !== "web") ||
              (types.includes("web") && entry.version === "web")
            ) {
              switch (entry.source) {
                case "recommendedShortlist":
                  recommended++;
                  break;
                case "allProducts":
                  allProducts++;
                  break;
                case "shortlist":
                  shortlist++;
                  break;
                case "post":
                  newsAndOffers++;
                  break;
                case "chat":
                  chat++;
                  break;
                case "cartUpsell":
                  cartUpsell++;
                  break;
                case "cart":
                  cart++;
                  break;
                case "homeUpsell":
                  homeUpsell++;
                  break;

                default:
                  console.log("Unknown case: " + entry.source);
              }
            }
          });
        }
      });

      return {
        labels: [
          "All products",
          "Recommended",
          "News & Offers",
          "Shortlist",
          "Chat",
          "Upsell",
          "Cart",
          "Home upsell",
        ],
        datasets: [
          {
            data: [
              allProducts,
              recommended,
              newsAndOffers,
              shortlist,
              chat,
              cartUpsell,
              cart,
              homeUpsell,
            ],
          },
        ],
      };
    },
  },
  actions: {
    bindAnalyticsDocs: async (context) => {
      // return context.bindFirestoreRef(
      //   "analyticsDocs",
      //   db.collection("analytics")
      // );

      const snap = await db.collection("analytics").get();

      let returnArr = [];

      if (snap.size > 0) {
        snap.forEach((doc) => {
          returnArr.push({
            id: doc.id,
            ...doc.data(),
          });
        });
      }

      context.commit("setAnalyticsDocs", returnArr);
    },
    initAnalytics: async (context, payload) => {
      let returnArr = [];

      if (context.state.analyticsDocs.length === 0) {
        await context.dispatch("bindAnalyticsDocs");
      }

      let docs = context.state.analyticsDocs;

      if (
        context.state.accountOwner !== "all" &&
        context.state.accountOwner !== null
      ) {
        docs = docs.filter(
          (doc) => doc.accountOwner === context.state.accountOwner
        );
      }

      const BATCH_SIZE = 500;
      const DELAY = 1000; // 1 second

      for (let i = 0; i < docs.length; i += BATCH_SIZE) {
        const batch = docs.slice(i, i + BATCH_SIZE);

        const promises = batch.map((user) =>
          db
            .collection("analytics")
            .doc(user.id)
            .collection("logs")
            .doc(payload.week)
            .get()
            .then((doc) => {
              const data = doc.data();

              if (data !== undefined) {
                let analyticsData = {
                  cart: data.cart !== undefined ? data.cart : {},
                  recommendedProducts:
                    data.recommendedProducts !== undefined
                      ? data.recommendedProducts
                      : {},
                  productSearch:
                    data.productSearch !== undefined ? data.productSearch : [],
                  productFilter:
                    data.productFilter !== undefined ? data.productFilter : [],
                  categoryIcons:
                    data.categoryIcons !== undefined ? data.categoryIcons : [],
                  uid: user.id,
                  active: user.active,
                  productPage:
                    data.productPage !== undefined ? data.productPage : {},
                };

                returnArr.push(analyticsData);
              }
            })
            .catch((error) => {
              console.log(error.message);
            })
        );

        await Promise.all(promises);
        await new Promise((resolve) => setTimeout(resolve, DELAY));
      }

      context.commit("setSelectedWeek", returnArr);
      context.commit("setInit", true);
    },

    initNewsAndOffersAnalytics: async (context, payload) => {
      const selectedWeek = payload.week;

      // Get first and last day of week, using mondays as first day of week
      const firstDay = Vue.moment()
        .year(selectedWeek.substring(0, 4))
        .week(selectedWeek.substring(4))
        .startOf("isoWeek")
        .toDate();

      const lastDay = Vue.moment()
        .year(selectedWeek.substring(0, 4))
        .week(selectedWeek.substring(4))
        .endOf("isoWeek")
        .toDate();

      let query;

      if (context.state.accountOwner === "all") {
        query = db
          .collection("posts")
          .where("startTime", ">", firstDay)
          .where("startTime", "<", lastDay);
      } else {
        const userId = context.rootState.settings.user.id;
        query = db
          .collection("posts")
          .where("startTime", ">", firstDay)
          .where("startTime", "<", lastDay)
          .where("authorId", "==", userId);
      }

      const snap = await query.get();

      let returnArr = [];

      if (snap.size > 0) {
        snap.forEach((doc) => {
          returnArr.push({
            id: doc.id,
            ...doc.data(),
            analytics: undefined,
          });
        });

        returnArr = await Promise.all(
          returnArr.map(async (post) => {
            const analytics = await db
              .collection("analytics")
              .doc("posts")
              .collection("posts")
              .doc(post.id)
              .get();

            const data = analytics.data();
            if (data !== undefined && data.postName !== undefined) {
              post.analytics = analytics.data();
            }
            return post;
          })
        );
      }

      context.commit("setNewsAndOffersAnalytics", returnArr);
    },
  },
};
export default analytics;
