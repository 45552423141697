var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-editor"},[_c('div',{staticClass:"app-slider-editor-wrapper"},[_c('div',{staticClass:"web-pages-current-blocks-container"},[(_vm.editingSliderItems.length > 0)?_c('div',{staticClass:"web-pages-current-blocks"},[_c('draggable',{on:{"start":_vm.onDragStart,"end":_vm.onDragEnd},model:{value:(_vm.editingSliderItems),callback:function ($$v) {_vm.editingSliderItems=$$v},expression:"editingSliderItems"}},_vm._l((_vm.editingSliderItems),function(block,index){return _c('div',{key:'block-' + index,staticClass:"web-pages-current-single-block",class:{ active: index === _vm.selectedSliderIndex },on:{"click":function($event){return _vm.selectBlock(index)}}},[_c('p',[_vm._v(_vm._s(block.type)+" - "+_vm._s(block.title.en))]),_c('span',{staticClass:"material-icons delete-icon",on:{"click":function($event){$event.stopPropagation();return _vm.deleteBlock(index)}}},[_vm._v("delete")])])}),0)],1):_c('div',{staticClass:"web-pages-no-blocks"},[_c('p',[_vm._v("No slides added yet")])]),_c('button',{on:{"click":_vm.addBlock}},[_vm._v("Add block")])]),(_vm.selectedSliderItem !== false)?_c('div',{staticClass:"web-pages-selected-block-wrapper"},[_c('div',{staticClass:"web-pages-universal-fields"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Type")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"news","value":"news"},domProps:{"checked":_vm.selectedSliderItem.type === 'news'},on:{"change":function($event){return _vm.$store.commit('webPages/updateSliderItem', {
                  key: 'type',
                  value: 'news',
                })}}}),_c('label',{attrs:{"for":"news"}},[_vm._v("News")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"page","value":"page"},domProps:{"checked":_vm.selectedSliderItem.type === 'page'},on:{"change":function($event){return _vm.$store.commit('webPages/updateSliderItem', {
                  key: 'type',
                  value: 'page',
                })}}}),_c('label',{attrs:{"for":"page"}},[_vm._v("Page")])])]),(_vm.selectedSliderItem.type === 'page')?_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Select page")]),_vm._l((_vm.webPages),function(page){return _c('div',{key:page.id,staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":page.id},domProps:{"value":page.id,"checked":_vm.selectedSliderItem.identifier === page.id},on:{"change":function($event){return _vm.$store.commit('webPages/updateSliderItem', {
                  key: 'identifier',
                  value: page.id,
                })}}}),_c('label',{attrs:{"for":page.id}},[_vm._v(_vm._s(page.id))])])})],2):_vm._e(),(_vm.selectedSliderItem.type === 'news')?_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Select news")]),_vm._l((_vm.availableNewsPosts),function(post){return _c('div',{key:post.id,staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":post.id},domProps:{"value":post.id,"checked":_vm.selectedSliderItem.identifier === post.id},on:{"change":function($event){return _vm.$store.commit('webPages/updateSliderItem', {
                  key: 'identifier',
                  value: post.id,
                })}}}),_c('label',{attrs:{"for":post.id}},[_vm._v(_vm._s(post.title))])])})],2):_vm._e(),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Image url")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":_vm.selectedSliderItem.image},on:{"input":function($event){return _vm.$store.commit('webPages/updateSliderItem', {
                key: 'image',
                value: $event.target.value,
              })}}})])]),_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,index){return _c('div',{key:'lang-' + index,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":_vm.selectedSliderItem.title[lang.code]},on:{"input":function($event){return _vm.$store.commit('webPages/updateSliderItem', {
                  key: 'title.' + lang.code,
                  value: $event.target.value,
                })}}})])])}),0)]):_vm._e()]),_c('WebPagesEditorActions')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }