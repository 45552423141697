var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-universal-fields"}),_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,index){return _c('div',{key:'lang-' + index,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Button text")]),_c('input',{attrs:{"type":"text","placeholder":"Button text"},domProps:{"value":_vm.selectedBlock.button[lang.code].text},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'button.' + lang.code + '.text',
              type: 'text',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("Button url - https if external, /xxx if internal")]),_c('input',{attrs:{"type":"text","placeholder":"Button url"},domProps:{"value":_vm.selectedBlock.button[lang.code].url},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'button.' + lang.code + '.url',
              type: 'trim',
              value: $event.target.value,
            })}}})]),_c('div',{staticClass:"form-input radio"},[_c('label',[_vm._v("Button style")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":'primary-' + lang.code,"value":"primary"},domProps:{"checked":_vm.selectedBlock.button[lang.code].style === 'primary'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'button.' + lang.code + '.style',
                type: 'text',
                value: 'primary',
              })}}}),_c('label',{attrs:{"for":'primary-' + lang.code}},[_vm._v("Primary")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":'secondary-' + lang.code,"value":"secondary"},domProps:{"checked":_vm.selectedBlock.button[lang.code].style === 'secondary'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
                key: 'button.' + lang.code + '.style',
                type: 'text',
                value: 'secondary',
              })}}}),_c('label',{attrs:{"for":'secondary-' + lang.code}},[_vm._v("Secondary")])])]),_c('div',{staticClass:"form-input check"},[_c('label',[_vm._v("Open in new tab")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedBlock.button[lang.code].target === '_blank'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'button.' + lang.code + '.target',
              type: 'text',
              value: $event.target.checked ? '_blank' : '',
            })}}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }