<template>
  <div
    class="recurring-order-company-selector"
    :class="{ disabled: eId !== 'create' }"
  >
    <div class="recurring-order-company-selected" @click="open = !open">
      <span v-if="eCompany !== null">{{
        getCompanyNameByVismaId(eCompany)
      }}</span>
      <span v-else>Välj företag</span>

      <span class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
          v-if="!open"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
          v-else
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
          />
        </svg>
      </span>
    </div>

    <div class="recurring-order-company-selector-dropdown" v-if="open">
      <div class="recurring-order-company-selector-search">
        <input
          type="text"
          placeholder="Search company"
          v-model="companySearch"
        />
      </div>
      <div class="recurring-order-companies-list">
        <div
          class="recurring-order-company"
          v-for="company in filteredCompanies"
          :key="company.id"
          @click="selectCompany(company.id)"
        >
          {{ company.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
export default {
  name: "RecurringOrderCompanySelector",
  data() {
    return {
      open: false,
      companySearch: "",
    };
  },
  computed: {
    ...mapState({
      eId: (state) => state.recurringOrders.eId,
      eCompany: (state) => state.recurringOrders.eCompany,
      companyList: (state) => state.settings.companyList.companies,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    filteredCompanies() {
      return Object.entries(this.companyList)
        .filter(([, name]) =>
          name.toLowerCase().includes(this.companySearch.toLowerCase())
        )
        .map(([id, name]) => ({ id, name }));
    },
  },
  mounted() {},
  async created() {
    console.log(this.companyList);
  },
  beforeDestroy() {},
  methods: {
    selectCompany: async function (id) {
      this.$store.commit("recurringOrders/setECompany", id);
      this.$store.commit("recurringOrders/setEDays", []);
      this.$store.commit("recurringOrders/setEOrderLines", []);
      this.open = false;
      this.companySearch = "";
      await this.$store.dispatch("recurringOrders/setupPriceList", id);
    },
  },
};
</script>
