var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-universal-fields"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Text size")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"h1","value":"h1"},domProps:{"checked":_vm.selectedBlock.size === 'h1'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'size',
              type: 'text',
              value: 'h1',
            })}}}),_c('label',{attrs:{"for":"h1"}},[_vm._v("H1")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"h2","value":"h2"},domProps:{"checked":_vm.selectedBlock.size === 'h2'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'size',
              type: 'text',
              value: 'h2',
            })}}}),_c('label',{attrs:{"for":"h2"}},[_vm._v("H2")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"h3","value":"h3"},domProps:{"checked":_vm.selectedBlock.size === 'h3'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'size',
              type: 'text',
              value: 'h3',
            })}}}),_c('label',{attrs:{"for":"h3"}},[_vm._v("H3")])])]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Text align")]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"left","value":"left"},domProps:{"checked":_vm.selectedBlock.align === 'left'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'align',
              type: 'text',
              value: 'left',
            })}}}),_c('label',{attrs:{"for":"left"}},[_vm._v("Left")])]),_c('div',{staticClass:"form-input-radio"},[_c('input',{attrs:{"type":"radio","id":"center","value":"center"},domProps:{"checked":_vm.selectedBlock.align === 'center'},on:{"change":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'align',
              type: 'text',
              value: 'center',
            })}}}),_c('label',{attrs:{"for":"center"}},[_vm._v("Center")])])])]),_c('div',{staticClass:"web-pages-lang-fields"},_vm._l((_vm.enabledLanguages),function(lang,index){return _c('div',{key:'lang-' + index,staticClass:"web-pages-lang-group"},[_c('img',{attrs:{"src":require('../../assets/flags/' + lang.code + '.png')}}),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Title")]),_c('input',{attrs:{"type":"text","placeholder":"Title"},domProps:{"value":_vm.selectedBlock.text[lang.code]},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
              key: 'text.' + lang.code,
              type: 'text',
              value: $event.target.value,
            })}}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }