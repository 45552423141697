import { db } from "../main.js";
import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import _ from "lodash";

const webPages = {
  namespaced: true,
  state: {
    init: false,
    webPages: [],
    editing: false,
    editingMenus: false,
    editingId: "",
    editingMetaTitle: "",
    editingMetaDescription: "",
    editingMetaImage: "",
    editingBlocks: [],
    selectedBlockIndex: null,
    menusDoc: null,
    editingTopMenuLoggedIn: {},
    editingTopMenuLoggedOut: {},
    editingBotMenuLoggedIn: {},
    editingBotMenuLoggedOut: {},
    editingFooterMenu1: {},
    editingFooterMenu2: {},
    editingFooterMenu3: {},
    editingFooterMenuBot: {},
    editingAppSlider: false,
    editingSliderItems: [],
    selectedSliderIndex: null,
    availableNewsPosts: [],
  },
  mutations: {
    setInit(state, payload) {
      state.init = payload;
    },
    setEditing(state, payload) {
      state.editing = payload;
    },
    setEditingId(state, payload) {
      state.editingId = payload;
    },
    setEditingMetaTitle(state, payload) {
      state.editingMetaTitle = payload;
    },
    setEditingMetaDescription(state, payload) {
      state.editingMetaDescription = payload;
    },
    setEditingMetaImage(state, payload) {
      state.editingMetaImage = payload;
    },
    setEditingBlocks(state, payload) {
      state.editingBlocks = payload;
    },
    addEditingBlock(state, payload) {
      state.editingBlocks.push(payload);
    },
    addSliderItem(state, payload) {
      state.editingSliderItems.push(payload);
    },
    setSelectedBlockIndex: (state, payload) => {
      state.selectedBlockIndex = payload;
    },
    updateBlock: (state, payload) => {
      const { key, value } = payload;
      if (state.editingBlocks[state.selectedBlockIndex]) {
        _.set(state.editingBlocks[state.selectedBlockIndex], key, value);
      }
    },
    updateSliderItem: (state, payload) => {
      const { key, value } = payload;
      if (state.editingSliderItems[state.selectedSliderIndex]) {
        _.set(state.editingSliderItems[state.selectedSliderIndex], key, value);
      }

      if (key === "type") {
        _.set(
          state.editingSliderItems[state.selectedSliderIndex],
          "identifier",
          null
        );
      }
    },
    updateMenu: (state, payload) => {
      const { key, value, type } = payload;
      _.set(state[type], key, value);
    },
    deleteBlock: (state, payload) => {
      state.editingBlocks.splice(payload, 1);
    },
    deleteSliderItem: (state, payload) => {
      state.editingSliderItems.splice(payload, 1);
    },
    addCoworker: (state, payload) => {
      state.editingBlocks[state.selectedBlockIndex].groups[
        payload.groupIndex
      ].coworkers.push(payload.userData);
    },
    removeCoworkerGroup: (state, payload) => {
      state.editingBlocks[state.selectedBlockIndex].groups.splice(payload, 1);
    },
    addCoworkerGroup: (state, payload) => {
      state.editingBlocks[state.selectedBlockIndex].groups.push(payload);
    },
    removeCoworker: (state, payload) => {
      state.editingBlocks[state.selectedBlockIndex].groups[
        payload.groupIndex
      ].coworkers.splice(payload.coworkerIndex, 1);
    },
    moveCoworkerUp: (state, payload) => {
      const { groupIndex, coworkerIndex } = payload;
      if (coworkerIndex > 0) {
        const group =
          state.editingBlocks[state.selectedBlockIndex].groups[groupIndex];
        const coworker = group.coworkers.splice(coworkerIndex, 1)[0];
        group.coworkers.splice(coworkerIndex - 1, 0, coworker);
      }
    },
    moveCoworkerDown: (state, payload) => {
      const { groupIndex, coworkerIndex } = payload;
      const group =
        state.editingBlocks[state.selectedBlockIndex].groups[groupIndex];
      if (coworkerIndex < group.coworkers.length - 1) {
        const coworker = group.coworkers.splice(coworkerIndex, 1)[0];
        group.coworkers.splice(coworkerIndex + 1, 0, coworker);
      }
    },
    setEditingMenus: (state, payload) => {
      state.editingMenus = payload;
    },
    setEditingTopMenuLoggedIn: (state, payload) => {
      state.editingTopMenuLoggedIn = payload;
    },
    setEditingTopMenuLoggedOut: (state, payload) => {
      state.editingTopMenuLoggedOut = payload;
    },
    setEditingBotMenuLoggedIn: (state, payload) => {
      state.editingBotMenuLoggedIn = payload;
    },
    setEditingBotMenuLoggedOut: (state, payload) => {
      state.editingBotMenuLoggedOut = payload;
    },
    setEditingFooterMenu1: (state, payload) => {
      state.editingFooterMenu1 = payload;
    },
    setEditingFooterMenu2: (state, payload) => {
      state.editingFooterMenu2 = payload;
    },
    setEditingFooterMenu3: (state, payload) => {
      state.editingFooterMenu3 = payload;
    },
    setEditingFooterMenuBot: (state, payload) => {
      state.editingFooterMenuBot = payload;
    },
    setEditingAppSlider: (state, payload) => {
      state.editingAppSlider = payload;
    },
    setEditingSliderItems: (state, payload) => {
      state.editingSliderItems = payload;
    },
    setSelectedSliderIndex: (state, payload) => {
      state.selectedSliderIndex = payload;
    },
    setAvailableNewsPosts: (state, payload) => {
      state.availableNewsPosts = payload;
    },
  },
  getters: {
    currentPage: (state) => {
      console.log(state.editing);
      console.log(state.webPages);
      const found = state.webPages.find((page) => page.id === state.editing);

      if (found === undefined) {
        console.log("not found" + state.editing);
        return false;
      }

      return found;
    },
    selectedBlock: (state) => {
      if (state.selectedBlockIndex === null) {
        return false;
      }

      return state.editingBlocks[state.selectedBlockIndex] || false;
    },
    selectedSliderItem: (state) => {
      if (state.selectedSliderIndex === null) {
        return false;
      }

      return state.editingSliderItems[state.selectedSliderIndex] || false;
    },
  },
  actions: {
    bindWebPages: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "webPages",
        db.collection("webPages").orderBy("updated", "desc")
      );
    }),
    bindMenus: firestoreAction((context) => {
      return context.bindFirestoreRef(
        "menusDoc",
        db.collection("webPages").doc("menus")
      );
    }),
    unbindWebPages: firestoreAction((context) => {
      return context.unbindFirestoreRef("webPages");
    }),
    setupEditing: ({ commit, state, getters }) => {
      if (!state.editing || state.editing === "create") {
        return;
      }
      commit("setEditingId", state.editing);
      commit("setEditingMetaTitle", getters.currentPage.metaTitle);
      commit("setEditingMetaDescription", getters.currentPage.metaDescription);
      commit("setEditingMetaImage", getters.currentPage.metaImage);
      commit("setEditingBlocks", getters.currentPage.blocks);
    },
    setupEditingMenus: ({ commit, state }) => {
      console.log("uhh");
      commit("setEditingTopMenuLoggedIn", state.menusDoc.topMenuLoggedIn);
      commit("setEditingTopMenuLoggedOut", state.menusDoc.topMenuLoggedOut);
      commit("setEditingBotMenuLoggedIn", state.menusDoc.botMenuLoggedIn);
      commit("setEditingBotMenuLoggedOut", state.menusDoc.botMenuLoggedOut);
      commit("setEditingFooterMenu1", state.menusDoc.footerMenu1);
      commit("setEditingFooterMenu2", state.menusDoc.footerMenu2);
      commit("setEditingFooterMenu3", state.menusDoc.footerMenu3);
      commit("setEditingFooterMenuBot", state.menusDoc.footerMenuBot);
    },
    setupEditingAppSlider: async ({ commit, rootState }) => {
      const items = JSON.parse(
        JSON.stringify(rootState.settings.appHelpers.appNewsAndPagesSlider)
      );

      const postsSnap = await db
        .collection("posts")
        .where("public", "==", true)
        .orderBy("createdAt", "desc")
        .get();

      const posts = postsSnap.docs.map((doc) => {
        return {
          id: doc.id,
          title: doc.data().mainTitle,
        };
      });

      commit("setAvailableNewsPosts", posts);
      commit("setEditingSliderItems", items);
      commit("setEditingAppSlider", true);
    },
    resetEditingAppSlider: ({ commit }) => {
      commit("setEditingSliderItems", []);
      commit("setEditingAppSlider", false);
      commit("setSelectedSliderIndex", null);
    },
    resetEditing: ({ commit }) => {
      commit("setEditing", false);
      commit("setEditingId", "");
      commit("setEditingMetaTitle", "");
      commit("setEditingMetaDescription", "");
      commit("setEditingMetaImage", "");
      commit("setEditingBlocks", []);
      commit("setSelectedBlockIndex", null);
    },
    deleteWebPage: async ({ state, dispatch }) => {
      const verify = confirm("Are you sure you want to delete this page?");

      if (verify) {
        console.log(state.editing);
        try {
          await db
            .collection("webPages")
            .doc(state.editing.toString())
            .delete();
          console.log("done");
        } catch (error) {
          console.error("Error removing document: ", error);
        }

        dispatch("resetEditing");
      }
    },
    saveWebPage: async ({ state, dispatch }) => {
      if (state.editingId.trim() === "") {
        return {
          success: false,
          message: "Please enter a valid ID",
        };
      }

      if (state.editingMetaTitle.trim() === "") {
        return {
          success: false,
          message: "Please enter a valid Meta Title",
        };
      }

      if (state.editingMetaDescription.trim() === "") {
        return {
          success: false,
          message: "Please enter a valid Meta Description",
        };
      }

      const data = {
        id: state.editingId,
        metaTitle: state.editingMetaTitle,
        metaDescription: state.editingMetaDescription,
        metaImage: state.editingMetaImage,
        blocks: state.editingBlocks,
        updated: firebase.firestore.Timestamp.now(),
      };

      await db.collection("webPages").doc(state.editingId).set(data);
      dispatch("resetEditing");
      return {
        success: true,
        message: "Page saved successfully",
      };
    },
    saveAppSlider: async ({ state, dispatch }) => {
      const data = state.editingSliderItems;
      await db.collection("app").doc("appHelpers").update({
        appNewsAndPagesSlider: data,
      });

      dispatch("resetEditingAppSlider");
      return {
        success: true,
        message: "Slider saved successfully",
      };
    },
    saveMenus: async ({ state, dispatch }) => {
      const data = {
        topMenuLoggedIn: state.editingTopMenuLoggedIn,
        topMenuLoggedOut: state.editingTopMenuLoggedOut,
        botMenuLoggedIn: state.editingBotMenuLoggedIn,
        botMenuLoggedOut: state.editingBotMenuLoggedOut,
        footerMenu1: state.editingFooterMenu1,
        footerMenu2: state.editingFooterMenu2,
        footerMenu3: state.editingFooterMenu3,
        footerMenuBot: state.editingFooterMenuBot,
      };

      await db.collection("webPages").doc("menus").set(data);
      dispatch("resetMenuEditing");
      return {
        success: true,
        message: "Menus saved successfully",
      };
    },
    resetMenuEditing: ({ commit }) => {
      commit("setEditingMenus", false);
      commit("setEditingTopMenuLoggedIn", {});
      commit("setEditingTopMenuLoggedOut", {});
      commit("setEditingBotMenuLoggedIn", {});
      commit("setEditingBotMenuLoggedOut", {});
      commit("setEditingFooterMenu1", {});
      commit("setEditingFooterMenu2", {});
      commit("setEditingFooterMenu3", {});
      commit("setEditingFooterMenuBot", {});
    },
    handleBlockInput({ commit }, payload) {
      const { value, type, key } = payload;

      if (type === "number") {
        parseInt(value);
      } else if (type === "trim") {
        value.trim();
      }

      commit("updateBlock", { key, value });
    },
  },
};
export default webPages;
