<template>
  <div id="sidebar" :class="{ active: menuOpen }">
    <div id="hamburger-button" @click="menuOpen = !menuOpen">
      <div id="hamburger" :class="{ active: menuOpen }"></div>
    </div>
    <div class="sidebar-header">
      <img
        v-if="appBrand === 'kvalitetsfisk'"
        :src="require('../assets/' + appBrand + '/logo-stamp.png')"
      />
      <img v-else :src="require('../assets/' + appBrand + '/logo.png')" />
      <h3>
        Admin
        <span
          v-if="$cookies.get('version') === 'dev'"
          @click="changeEnvironment('live')"
          >Dev</span
        >
        <span
          v-if="$cookies.get('version') === 'live'"
          @click="changeEnvironment('dev')"
          >Live</span
        >
      </h3>
    </div>
    <div class="maintenance" v-if="isMaintenance">
      <span
        >App status:
        <span class="bold">{{
          isMaintenance ? "Maintenance" : "Live"
        }}</span></span
      >
    </div>
    <ul class="sidebar-nav">
      <li>
        <router-link to="/home" @click.native="menuOpen = false">
          <span class="material-icons">home</span>
          Home
        </router-link>
      </li>
      <li>
        <router-link to="/companies" @click.native="menuOpen = false">
          <span class="material-icons">grade</span>
          Companies
        </router-link>
      </li>
      <li>
        <router-link to="/users" @click.native="menuOpen = false">
          <span class="material-icons">people_alt</span>
          Users
          <span
            class="sidebar-notification user-removal"
            v-if="Object.keys(requestedRemovalUsers).length > 0"
            >{{ Object.keys(requestedRemovalUsers).length }}</span
          >
          <span
            class="sidebar-notification"
            v-if="usersAwaitingApproval.length > 0"
            >{{ usersAwaitingApproval.length }}</span
          >
        </router-link>
      </li>
      <li>
        <router-link to="/products" @click.native="menuOpen = false">
          <span class="material-icons">sell</span>
          Products
        </router-link>
      </li>
      <li
        v-if="
          appBrand === 'kvalitetsfisk' ||
          appBrand === 'fiskidag' ||
          appBrand === 'fsg' ||
          appBrand === 'fiskerikajen'
        "
      >
        <router-link to="/categories" @click.native="menuOpen = false">
          <span class="material-icons">category</span>
          Categories
        </router-link>
      </li>
      <li v-if="appBrand === 'fsg'">
        <router-link to="/products-gs1" @click.native="menuOpen = false">
          <span class="material-icons">sell</span>
          Products GS1
        </router-link>
      </li>
      <li v-if="appBrand !== 'fiskerikajen'">
        <router-link to="/price-lists" @click.native="menuOpen = false">
          <span class="material-icons">price_change</span>
          Price lists
        </router-link>
      </li>
      <li>
        <router-link to="/shared-shortlists" @click.native="menuOpen = false">
          <span class="material-icons">list_alt</span>
          Shared shortlists
        </router-link>
      </li>
      <li>
        <router-link to="/company-shortlists" @click.native="menuOpen = false">
          <span class="material-icons">format_list_bulleted</span>
          Company shortlists
        </router-link>
      </li>
      <li>
        <router-link
          to="/mass-edit-shortlists"
          @click.native="menuOpen = false"
        >
          <span class="material-icons">fact_check</span>
          Mass edit shortlists
        </router-link>
      </li>
      <!-- <li>
        <router-link to="/mass-add-shortlists" @click.native="menuOpen = false">
          <span class="material-icons">playlist_add</span>
          Mass add shortlists
        </router-link>
      </li> -->
      <li
        v-if="
          userRole.slug === 'superAdmin' ||
          appBrand === 'kvalitetsfisk' ||
          appBrand === 'fiskidag'
        "
      >
        <router-link to="/cart-upsell" @click.native="menuOpen = false">
          <span class="material-icons">add_shopping_cart</span>
          Upsell
        </router-link>
      </li>
      <li>
        <router-link to="/orders" @click.native="menuOpen = false">
          <span class="material-icons">receipt_long</span>
          Orders
          <span
            class="sidebar-notification order"
            v-if="offlineOrderCount > 0"
            >{{ offlineOrderCount }}</span
          ></router-link
        >
      </li>
      <li v-if="appBrand === 'kvalitetsfisk'">
        <router-link to="/subscriptions" @click.native="menuOpen = false">
          <span class="material-icons">event_repeat</span>
          Subscriptions
        </router-link>
      </li>
      <li>
        <router-link to="/notifications">
          <span class="material-icons">notifications_active</span>
          News & offers
        </router-link>
      </li>
      <li v-if="userRole.slug === 'superAdmin'">
        <router-link to="/popups">
          <span class="material-icons">dvr</span>
          Popups
        </router-link>
      </li>
      <li
        v-if="
          (appBrand == 'kvalitetsfisk' ||
            appBrand == 'fiskidag' ||
            (appBrand == 'fsg' && $cookies.get('version') === 'dev') ||
            (appBrand == 'fiskerikajen' &&
              $cookies.get('version') === 'dev')) &&
          userRole.slug === 'superAdmin'
        "
      >
        <router-link to="/surveys">
          <span class="material-icons">mood</span>
          Surveys
        </router-link>
      </li>
      <li
        v-if="
          (userRole.slug === 'superAdmin' && appBrand === 'kvalitetsfisk') ||
          (appBrand === 'fsg' && $cookies.get('version') === 'dev')
        "
      >
        <router-link to="/web-pages">
          <span class="material-icons">window</span>
          Web pages
        </router-link>
      </li>
      <li>
        <router-link to="/chat" @click.native="menuOpen = false">
          <span class="material-icons">forum</span>
          Chat
          <span
            class="sidebar-notification user"
            v-if="notificationCountByAdmin(userVismaId) > 0"
            >{{ notificationCountByAdmin(userVismaId) }}</span
          >
          <span
            class="sidebar-notification"
            v-if="
              totalNotificationCount - notificationCountByAdmin(userVismaId) > 0
            "
            >{{
              totalNotificationCount - notificationCountByAdmin(userVismaId)
            }}</span
          >
        </router-link>
      </li>
    </ul>
    <ul class="sidebar-nav nav-bottom">
      <li>
        <router-link to="/app-settings" @click.native="menuOpen = false">
          <span class="material-icons">settings</span>
          App settings
        </router-link>
      </li>
      <li>
        <router-link to="/languages" @click.native="menuOpen = false">
          <span class="material-icons">translate</span>
          Languages
        </router-link>
      </li>
      <li v-if="appBrand !== 'fiskidag'">
        <router-link to="/analytics" @click.native="menuOpen = false">
          <span class="material-icons">insert_chart</span>
          Analytics
        </router-link>
      </li>
      <li
        v-if="
          appBrand === 'fiskidag' ||
          appBrand === 'fsg' ||
          appBrand === 'kvalitetsfisk' ||
          (appBrand === 'fiskerikajen' && $cookies.get('version') === 'dev')
        "
      >
        <router-link to="/register-analytics" @click.native="menuOpen = false">
          <span class="material-icons">insights</span>
          Register analytics
        </router-link>
      </li>
      <li v-if="userRole.slug === 'admin' || userRole.slug === 'superAdmin'">
        <router-link to="/system" @click.native="menuOpen = false"
          ><span class="material-icons">dns</span>System</router-link
        >
      </li>
      <li v-if="userRole.slug === 'superAdmin'">
        <router-link to="/dev" @click.native="menuOpen = false"
          ><span class="material-icons">construction</span>Dev</router-link
        >
      </li>
      <li>
        <a href="#" @click.prevent="logout">
          <span class="material-icons">logout</span>
          Log out
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import { mapState, mapGetters } from "vuex";

// let audio = new Audio(require('@/assets/message.mp3'))

export default {
  name: "Sidebar",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    logout: function () {
      firebase.auth().signOut();
    },
    changeEnvironment: function (to) {
      let confirm = window.confirm(
        "About to change to: " +
          to +
          " \r\nPage will reload, do you wish to continue?"
      );

      if (confirm) {
        this.$cookies.set("version", to);
        location.reload();
      }
    },
  },
  computed: {
    ...mapState({
      userId: (state) => state.settings.user.id,
      userVismaId: (state) => state.settings.user.vismaId,
      appBrand: (state) => state.settings.appSettings.app,
      appEnv: (state) => state.settings.appSettings.env,
    }),
    ...mapGetters("settings", ["userRole", "isMaintenance"]),
    ...mapGetters("chat", [
      "totalNotificationCount",
      "notificationCountByAdmin",
    ]),
    ...mapGetters("orders", ["offlineOrderCount"]),
    ...mapGetters("users", ["requestedRemovalUsers", "usersAwaitingApproval"]),
  },
};
</script>

<style lang="scss" scoped>
.maintenance {
  width: 100%;
  margin-bottom: 10px;
  .bold {
    font-weight: 600;
  }
  &span {
    text-align: center;
  }
}
</style>
