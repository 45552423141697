<template>
  <div class="recurring-order-editor">
    <RecurringOrderCompanySelector />

    <div
      class="recurring-order-editor-container"
      :class="{ inactive: eCompany === null }"
    >
      <input type="text" v-model="eName" placeholder="Namn" />
      <RecurringOrderDaySelector />

      <RecurringOrderProductSelector />

      <input type="text" v-model="eNote" placeholder="Notering" />

      <div class="status-wrapper">
        <div class="status-buttons">
          <button
            :class="['status-button', { active: eActive }]"
            @click="setActive(true)"
          >
            Aktiv
            <span v-if="eActive" class="status-indicator success"></span>
          </button>
          <button
            :class="['status-button', { active: !eActive }]"
            @click="setActive(false)"
          >
            Pausad
            <span v-if="!eActive" class="status-indicator error"></span>
          </button>
        </div>
      </div>
    </div>
    <RecurringOrderActions />
  </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
export default {
  name: "RecurringOrderEditor",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      eDays: (state) => state.recurringOrders.eDays,
      eCompany: (state) => state.recurringOrders.eCompany,
      eActive: (state) => state.recurringOrders.eActive,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    eName: {
      get() {
        return this.$store.state.recurringOrders.eName;
      },
      set(value) {
        this.$store.commit("recurringOrders/setEName", value);
      },
    },
    eNote: {
      get() {
        return this.$store.state.recurringOrders.eNote;
      },
      set(value) {
        this.$store.commit("recurringOrders/setENote", value);
      },
    },
  },
  mounted() {},
  async created() {},
  beforeDestroy() {},
  methods: {
    setActive: function (active) {
      this.$store.commit("recurringOrders/setEActive", active);
    },
  },
};
</script>
