var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-pages-hero-editing web-pages-editing-block"},[_c('div',{staticClass:"web-pages-universal-fields"},[_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Image url")]),_c('input',{attrs:{"type":"text"},domProps:{"value":_vm.selectedBlock.url},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
            key: 'url',
            type: 'text',
            value: $event.target.value,
          })}}})]),_c('div',{staticClass:"form-input"},[_c('label',[_vm._v("Alt text")]),_c('input',{attrs:{"type":"text"},domProps:{"value":_vm.selectedBlock.alt},on:{"input":function($event){return _vm.$store.dispatch('webPages/handleBlockInput', {
            key: 'alt',
            type: 'text',
            value: $event.target.value,
          })}}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }