<template>
  <div class="recurring-order-product-selector">
    <div class="recurring-order-selected-products">
      <div
        class="recurring-order-selected-product"
        v-for="product in eOrderLines"
        :key="product.productFirebaseId"
      >
        <div class="recurring-order-selected-product-meta">
          <span class="id">{{ product.productVismaId }}</span>
          <span class="name">{{
            getProductByFirebaseId(product.productFirebaseId).name
          }}</span>
        </div>
        <div class="recurring-order-selected-product-actions">
          <div class="recurring-order-selected-product-count-remove">
            <input type="number" v-model="product.count" />
            <span>
              {{ getProductByFirebaseId(product.productFirebaseId).unit }}</span
            >
            <span
              class="action remove"
              @click="productRemove(product.productFirebaseId)"
              >Remove</span
            >
          </div>
          <input
            type="text"
            v-model="product.note"
            placeholder="Note..."
            maxlength="27"
          />
        </div>
      </div>
    </div>

    <div class="shortlists-products-search">
      <input type="search" placeholder="Search product..." v-model="search" />
      <div class="shortlist-search-container">
        <div v-for="product in getProductsBySearch(search)" :key="product.id">
          <div v-if="showProduct(product.vismaId)">
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="pic">
              <span v-if="product.image != ''" class="material-icons">
                image
              </span>
            </span>
            <span class="name">{{ product.name }}</span>
            <span class="action add" @click="productAdd(product)">Add</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "RecurringOrderProductSelector",
  data() {
    return {
      days: [],
      search: "",
    };
  },
  computed: {
    ...mapState({
      eName: (state) => state.recurringOrders.eName,
      eNote: (state) => state.recurringOrders.eNote,
      eDays: (state) => state.recurringOrders.eDays,
      eCompany: (state) => state.recurringOrders.eCompany,
      eActive: (state) => state.recurringOrders.eActive,
      eOrderLines: (state) => state.recurringOrders.eOrderLines,
      ePriceList: (state) => state.recurringOrders.ePriceList,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("products", [
      "getProductsBySearch",
      "getProductByFirebaseId",
    ]),
  },
  mounted() {
    console.log(this.eOrderLines);
  },
  async created() {},
  beforeDestroy() {},
  methods: {
    showProduct: function (productId) {
      if (
        this.ePriceList === null ||
        this.ePriceList[productId] === undefined ||
        this.eOrderLines.find((line) => line.productVismaId === productId) !==
          undefined
      ) {
        return false;
      }
      return true;
    },
    productRemove: function (firebaseId) {
      let newLines = JSON.parse(JSON.stringify(this.eOrderLines));
      newLines = newLines.filter(
        (line) => line.productFirebaseId !== firebaseId
      );
      this.$store.commit("recurringOrders/setEOrderLines", newLines);
    },
    productAdd: function (product) {
      let newLines = JSON.parse(JSON.stringify(this.eOrderLines));
      newLines.push({
        productVismaId: product.vismaId,
        productFirebaseId: product.firebaseId,
        count: 1,
        note: "",
        name: product.name,
      });
      this.$store.commit("recurringOrders/setEOrderLines", newLines);
    },
  },
};
</script>
