<template>
  <div class="recurring-order-day-selector">
    <button
      v-for="(day, i) in days"
      :key="'day-' + i"
      :class="{ active: eDays.includes(i), blocked: day.blocked }"
      @click="selectDay(day)"
    >
      {{ day.name }}
    </button>
  </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
export default {
  name: "RecurringOrderDaySelector",
  data() {
    return {
      dayLabels: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
      days: [],
    };
  },
  computed: {
    ...mapState({
      eId: (state) => state.recurringOrders.eId,
      eCompany: (state) => state.recurringOrders.eCompany,
      eDays: (state) => state.recurringOrders.eDays,
      recurringOrders: (state) => state.recurringOrders.recurringOrders,
      appSettings: (state) => state.settings.appSettings,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
  },
  watch: {
    eCompany: function () {
      this.setupDays();
    },
  },
  mounted() {
    this.setupDays();
  },
  async created() {},
  methods: {
    setupDays: function () {
      this.days = [];
      const recurringBlocked = [];
      const company = this.getCompanyByVismaId(this.eCompany);

      this.recurringOrders.forEach((order) => {
        if (order.id === this.eId) return;
        if (order.companyVismaId !== parseInt(this.eCompany)) return;

        order.days.forEach((day) => {
          recurringBlocked.push(day + 1);
        });
      });

      for (let i = 1; i < 8; i++) {
        const name = this.dayLabels[i - 1];
        const id = i;

        const blocked =
          this.appSettings.deliveryBlockedDays.includes(i) ||
          (company &&
            this.appSettings.deliveryCutOff[company.deliveryRoute] &&
            this.appSettings.deliveryCutOff[
              company.deliveryRoute
            ].blockedDays.includes(i)) ||
          recurringBlocked.includes(i);

        this.days.push({ id, name, blocked });
      }
    },
    selectDay: function (day) {
      if (day.blocked) return;
      // Creaste new array
      if (this.eDays.includes(day.id - 1)) {
        // Remove the day from eDays
        this.$store.commit(
          "recurringOrders/setEDays",
          this.eDays.filter((d) => d !== day.id - 1)
        );
      } else {
        // Add the day to eDays
        this.$store.commit("recurringOrders/setEDays", [
          ...this.eDays,
          day.id - 1,
        ]);
      }
    },
  },
  beforeDestroy() {},
};
</script>
