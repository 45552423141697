<template>
  <div class="web-pages-hero-editing web-pages-editing-block">
    <div class="web-pages-universal-fields">
      <div class="form-input">
        <label>Layout</label>
        <div class="form-input-radio">
          <input
            type="radio"
            id="horizontal"
            value="horizontal"
            :checked="selectedBlock.layout === 'horizontal'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'layout',
                type: 'text',
                value: 'horizontal',
              })
            "
          />

          <label for="horizontal">Horizontal</label>
        </div>

        <div class="form-input-radio">
          <input
            type="radio"
            id="vertical"
            value="vertical"
            :checked="selectedBlock.layout === 'vertical'"
            @change="
              $store.dispatch('webPages/handleBlockInput', {
                key: 'layout',
                type: 'text',
                value: 'vertical',
              })
            "
          />
          <label for="vertical">Vertical</label>
        </div>
      </div>

      <div class="form-input">
        <label>Produkter</label>
        <draggable
          class="shortlists-products-inner"
          v-model="selectedBlock.products"
          @start="onDragStart"
          @end="onDragEnd"
        >
          <div
            v-for="product in selectedBlock.products"
            :key="'selected-' + product"
          >
            <span class="name">{{ getProductByFirebaseId(product).name }}</span>
            <span class="action remove" @click="productRemove(product)"
              >Remove</span
            >
          </div>
        </draggable>
      </div>

      <div class="shortlists-products-search">
        <input type="search" placeholder="Search product..." v-model="search" />
        <div class="shortlist-search-container">
          <div v-for="product in getProductsBySearch(search)" :key="product.id">
            <span class="id">{{ product.vismaId }}</span>
            <span class="stock">{{ product.stock }}{{ product.unit }}</span>
            <span class="pic">
              <span v-if="product.image != ''" class="material-icons">
                image
              </span>
            </span>
            <span class="name">{{ product.name }}</span>
            <span
              class="action remove"
              v-if="selectedBlock.products.includes(product.firebaseId)"
              @click="productRemove(product.firebaseId)"
              >Remove</span
            >
            <span
              v-else
              class="action add"
              @click="productAdd(product.firebaseId)"
              >Add</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "WebPagesProductsForm",
  data() {
    return {
      search: "",
    };
  },
  components: {
    draggable,
  },
  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
    ...mapGetters("products", [
      "getProductsBySearch",
      "getProductByFirebaseId",
    ]),
  },
  mounted() {},
  methods: {
    productAdd: function (productId) {
      this.$store.dispatch("webPages/handleBlockInput", {
        key: "products",
        type: "array",
        value: [...this.selectedBlock.products, productId],
      });
    },
    productRemove: function (productId) {
      this.$store.dispatch("webPages/handleBlockInput", {
        key: "products",
        type: "array",
        value: this.selectedBlock.products.filter((id) => id !== productId),
      });
    },
    onDragStart: function (evt) {
      console.log(evt);
      // this.$store.commit("notifications/setSelectedBlockIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      console.log({
        item: evt.item,
        from: evt.from,
        to: evt.to,
        oldIndex: evt.oldIndex,
        newIndex: evt.newIndex,
      });

      console.log(this.products);

      // this.$store.commit("notifications/setSelectedBlockIndex", evt.newIndex);
    },
  },
};
</script>

<style scoped>
.web-pages-universal-fields {
  width: 450px;
}

.shortlist-search-container {
  max-height: 400px;
}

.shortlists-products-inner > div {
  cursor: grab;
  padding-left: 10px;
}

.form-input {
  margin-bottom: 20px;
}
</style>
