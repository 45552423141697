<template>
  <div class="shortlists-selection-inner">
    <h4>Shortlists</h4>

    <simplebar class="shortlists-list" data-simplebar-auto-hide="false">
      <div
        v-for="list in currentShortlists(search)"
        :key="list.id"
        class="shortlist-item"
        :class="{ active: selectedShortlist === list.id }"
        @click="selectShortlist(list.id)"
      >
        <span class="name">{{ list.nameInternal }}</span>
        <span class="created-by">{{
          getUserNameById(list.author.firebaseId)
        }}</span>
        <span class="updated">{{
          list.updated.toDate() | moment("YYYY-MM-DD - HH:mm")
        }}</span>
      </div>
    </simplebar>

    <div class="shortlist-search">
      <span class="material-icons">search</span>
      <input
        @input="debounceSearch"
        type="search"
        ref="shortlistSearch"
        placeholder="Search shortlist..."
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "SharedShortlistsSelector",
  data() {
    return {
      search: "",
      timeout: null,
    };
  },
  components: {
    simplebar,
  },
  methods: {
    debounceSearch: function () {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.search = this.$refs.shortlistSearch.value;
      }, 500);
    },
    selectShortlist: function (listId) {
      console.log(listId);
      this.$store.commit("sharedShortlists/setSelectedShortlist", listId);
      this.$store.dispatch("sharedShortlists/setupEditing");
    },
  },
  computed: {
    ...mapState({
      selectedShortlist: (state) => state.sharedShortlists.selectedShortlist,
    }),
    ...mapGetters("sharedShortlists", ["currentShortlists"]),
    ...mapGetters("users", ["getUserNameById"]),
  },
};
</script>
