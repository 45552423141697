<template>
  <div class="web-pages-builder-wrapper">
    <div class="web-pages-current-blocks-wrapper">
      <WebPagesCurrentBlocks />
      <WebPagesBlockSelector />
    </div>
    <div
      v-if="selectedBlock !== false"
      class="web-pages-selected-block-wrapper"
    >
      <WebPagesHeroBlockForm v-if="selectedBlock.type === 'hero'" />
      <WebPagesUpsellBlockForm v-if="selectedBlock.type === 'upsell'" />
      <WebPagesNewsBlockForm v-if="selectedBlock.type === 'news'" />
      <WebPagesContentBoxForm v-if="selectedBlock.type === 'content-box'" />
      <WebPagesTwoContentBoxesForm
        v-if="selectedBlock.type === '2-content-boxes'"
      />
      <WebPagesCoworkersBlockForm v-if="selectedBlock.type === 'coworkers'" />
      <WebPagesTitleForm v-if="selectedBlock.type === 'title'" />
      <WebPagesTextForm v-if="selectedBlock.type === 'text'" />
      <WebPagesProductsForm v-if="selectedBlock.type === 'products'" />
      <WebPagesImageForm v-if="selectedBlock.type === 'image'" />
      <WebPagesButtonForm v-if="selectedBlock.type === 'button'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WebPagesBuilder",
  data() {
    return {};
  },

  computed: {
    ...mapGetters("webPages", ["selectedBlock"]),
    ...mapGetters("languages", ["enabledLanguages"]),
  },
  mounted() {},
  methods: {},
};
</script>
